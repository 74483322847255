import { useLayoutEffect, useState } from "react";

import { Box, type SxProps, type Theme } from "@mui/material";

import {
  CommentEditMessage,
  type CommentMessageValues,
} from "@ll-web/features/projectComments/components/CommentEditMessage";
import { ThreadBubble } from "@ll-web/features/videoPlayer/comments/components/ThreadBubble";

type NewVideoThreadProps = {
  cancel: () => void;
  onAddThread: (values: CommentMessageValues) => Promise<void>;
  sx: SxProps<Theme>;
};

export const NewVideoThread = ({
  cancel,
  onAddThread,
  sx,
}: NewVideoThreadProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useLayoutEffect(() => {
    // popover doesn't anchor properly when initially ope
    setIsOpen(true);
  }, []);

  return (
    <ThreadBubble
      threadId={null}
      index={null}
      sx={sx}
      isOpen={isOpen}
      setIsOpen={cancel}
    >
      <Box sx={{ width: 350 }}>
        <CommentEditMessage
          editMode="newComment"
          onSubmit={onAddThread}
          onCancel={cancel}
          sx={{ p: 2 }}
        />
      </Box>
    </ThreadBubble>
  );
};
