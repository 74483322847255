import { useCallback, useMemo, useState } from "react";

import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { mapProjectToAnalytics } from "@ll-web/core/analytics/eventUtils";
import type { TypedTrackingEvent } from "@ll-web/core/analytics/types";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import {
  useGetBrandById,
  useGetBrandByProjectId,
} from "@ll-web/features/brands/async/useBrandsQueries";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";
import { UsersQueries } from "@ll-web/features/users/async/useUsersQueries";
import { useBatchQuery } from "@ll-web/utils/hooks/useBatchQueries";
import { defined } from "@ll-web/utils/types/types";

import { notifyReviewAnalyticsEventsMap } from "./consts";
import {
  getDefaultReceiverForNotificationType,
  getNotifyFormDefaultValues,
} from "./defaults";
import type { NotifyForReviewFormValues } from "./notify-for-review.schema";
import {
  NotifyForReviewModal,
  type NotifyForReviewModalProps,
} from "./NotifyForReviewModal";
import type { ReviewNotificationType } from "./types";

type UseNotifyForReviewDialogArgs = {
  onSubmit: (payload: NotifyForReviewFormValues) => Promise<void>;
  onCancel?: VoidFunction;
  dialogProps: Omit<
    NotifyForReviewModalProps,
    | "onSubmitForReview"
    | "open"
    | "close"
    | "project"
    | "defaultEmailValues"
    | "defaultReceivers"
    | "defaultReceiversData"
    | "isLoading"
    | "notificationType"
  > & {
    notificationType: ReviewNotificationType;
  };
};

export const useNotifyForReviewDialog = ({
  onSubmit,
  onCancel,
  dialogProps,
}: UseNotifyForReviewDialogArgs) => {
  const { activeUser } = useActiveUser();
  const { activeProject } = useActiveProject();
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);

  const brandByProjectQuery = useGetBrandByProjectId({
    projectId: activeProject.id,
  });
  const brandId = brandByProjectQuery.data?._id;

  const brandByIdQuery = useGetBrandById(
    {
      brandId: brandId!,
    },
    { enabled: !!brandId },
  );

  const projectTeam =
    brandByIdQuery?.data?.team?.filter((teamMember) => !!teamMember.user) ?? [];

  const defaultReceivers = getDefaultReceiverForNotificationType[
    dialogProps.notificationType
  ](projectTeam)
    .map((receiverProfile) => receiverProfile.user?._id)
    .filter(defined);

  const defaultUsersQuery = useBatchQuery(
    defaultReceivers.map((userId) => UsersQueries.getById({ userId })),
  );

  const defaultReceiversData = useMemo(() => {
    return defaultUsersQuery.data?.filter(defined) ?? [];
  }, [defaultUsersQuery.data]);

  const defaultEmailValues = getNotifyFormDefaultValues({
    projectName: activeProject.title,
    notificationType: dialogProps.notificationType,
    defaultReceivers,
    defaultReceiversData: defaultUsersQuery.data?.filter(defined) ?? [],
    projectId: activeProject.id,
    brandName: brandByIdQuery.data?.name,
    msa: activeProject.msa,
    projectStyle: activeProject.style,
  });

  const openNotifyModal = useCallback(() => {
    setIsNotifyModalOpen(true);
  }, []);

  const handleConfirm = useCallback(
    async (payload: NotifyForReviewFormValues) => {
      const events =
        notifyReviewAnalyticsEventsMap[dialogProps.notificationType];

      if (events) {
        activityTracker.log({
          type: events.submitting,
          metadata: {
            ...mapProjectToAnalytics(activeProject),
          },
        } as TypedTrackingEvent);
      }

      await onSubmit(payload);
      setIsNotifyModalOpen(false);
    },
    [onSubmit, dialogProps.notificationType, activeProject],
  );

  const handleCancel = useCallback(() => {
    const events = notifyReviewAnalyticsEventsMap[dialogProps.notificationType];

    if (events) {
      activityTracker.log({
        type: events.cancel,
        metadata: {
          senderRole: activeUser.accountType,
          ...mapProjectToAnalytics(activeProject),
        },
      } as TypedTrackingEvent);
    }

    setIsNotifyModalOpen(false);
    onCancel?.();
  }, [onCancel, dialogProps.notificationType, activeProject, activeUser]);

  const notifyForReviewDialogNode = useMemo(() => {
    return (
      <NotifyForReviewModal
        onSubmitForReview={handleConfirm}
        open={isNotifyModalOpen}
        close={handleCancel}
        defaultEmailValues={defaultEmailValues}
        isLoading={brandByIdQuery.isFetching || defaultUsersQuery.isFetching}
        defaultReceiversData={defaultReceiversData}
        {...dialogProps}
      />
    );
  }, [
    handleConfirm,
    isNotifyModalOpen,
    handleCancel,
    dialogProps,
    defaultEmailValues,
    brandByIdQuery.isFetching,
    defaultUsersQuery.isFetching,
    defaultReceiversData,
  ]);

  return useMemo(
    () => ({
      openNotifyModal,
      notifyForReviewDialogNode,
      isNotifyModalOpen,
    }),
    [openNotifyModal, notifyForReviewDialogNode, isNotifyModalOpen],
  );
};
