import { alpha, IconButton, styled, type IconButtonProps } from "@mui/material";

// Custom<IconButton> https://www.figma.com/design/Ou7RmFBoNfHYu6LP06dW6h/Lemonlight-Material-UI-5.16.0?node-id=2036-27567&m=dev
export const CustomIconButton = styled(IconButton)(({ theme, color }) => {
  const bgColors: Record<
    NonNullable<IconButtonProps["color"]>,
    string | undefined
  > = {
    default: alpha(theme.palette.text.primary, 0.06),
    primary: theme.palette.blue[50],
    secondary: undefined,
    error: undefined,
    warning: undefined,
    success: undefined,
    info: undefined,
    inherit: alpha(theme.palette.text.primary, 0.06),
    inheritWhite: theme.palette.common.white,
  };

  return {
    backgroundColor: color ? bgColors[color] : bgColors.default,
    "&.Mui-disabled": {
      backgroundColor:
        !color || bgColors[color]
          ? theme.palette.action.disabledBackground
          : "none",
    },
    ...(color === "inheritWhite" && {
      "&:not(.Mui-disabled)": {
        color: theme.palette.text.primary,
        backgroundColor: bgColors[color],
        "&:hover": {
          backgroundColor: theme.palette.grey[200],
        },
      },
    }),
  };
});
