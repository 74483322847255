import { ActivityType } from "@ll-web/core/analytics/events";

import { CreationNotificationType, ReviewNotificationType } from "./types";

export const notifyReviewToastMessagesMap = (
  reviewNotificationType: ReviewNotificationType | CreationNotificationType,
): {
  success?: string;
  error?: string;
  pending?: string;
} => {
  switch (reviewNotificationType) {
    case CreationNotificationType.ContractCreated:
    case CreationNotificationType.LegacyFlowProjectCreated:
      return {
        pending: "Sending email notification...",
        success: "Email notification sent!",
      };
    case ReviewNotificationType.CreativeBriefInternal:
    case ReviewNotificationType.WizardGenerateExternal:
    case ReviewNotificationType.WizardGenerateInternal:
      return {
        pending: "Submitting Creative Brief...",
        success: "Creative Brief submitted!",
      };
    case ReviewNotificationType.CreativeDeckEditExternal:
    case ReviewNotificationType.CreativeDeckEditInternal:
      return {
        pending: "Submitting changes to Creative Deck...",
      };
  }
};

export const notifyReviewAnalyticsEventsMap: Record<
  ReviewNotificationType | CreationNotificationType,
  { submitting: ActivityType; cancel: ActivityType } | undefined
> = {
  [CreationNotificationType.ContractCreated]: undefined,
  [CreationNotificationType.LegacyFlowProjectCreated]: undefined,
  [ReviewNotificationType.CreativeBriefInternal]: {
    submitting: ActivityType.CreativeBriefInputClickedSubmitForReview,
    cancel: ActivityType.CreativeBriefInputCanceledSubmittingForReview,
  },
  [ReviewNotificationType.WizardGenerateInternal]: {
    submitting: ActivityType.CreativeBriefClickedSubmitForReview,
    cancel: ActivityType.CreativeBriefCanceledSubmittingForReview,
  },
  [ReviewNotificationType.WizardGenerateExternal]: {
    submitting: ActivityType.CreativeBriefClickedSubmitForReview,
    cancel: ActivityType.CreativeBriefCanceledSubmittingForReview,
  },
  [ReviewNotificationType.CreativeDeckEditInternal]: undefined,
  [ReviewNotificationType.CreativeDeckEditExternal]: undefined,
};

export const notifyReviewModalTitleMap: Record<
  ReviewNotificationType | CreationNotificationType,
  string
> = {
  [CreationNotificationType.ContractCreated]: "Notify Client Setup",
  [CreationNotificationType.LegacyFlowProjectCreated]: "Notify Client Setup",
  [ReviewNotificationType.CreativeBriefInternal]: "Notify Producer for Review",
  [ReviewNotificationType.WizardGenerateInternal]: "Notify Client for Review",
  [ReviewNotificationType.WizardGenerateExternal]: "Notify Producer for Review",
  [ReviewNotificationType.CreativeDeckEditInternal]: "Notify Client for Review",
  [ReviewNotificationType.CreativeDeckEditExternal]:
    "Notify Producer for Review",
};

export const BLOCKING_PHRASES = {
  hubspotLink: {
    text: "[ADD YOUR HUBSPOT LINK]",
    errorMessage:
      "Please replace the placeholder [ADD YOUR HUBSPOT LINK] with a valid link or remove it before submitting.",
  },
} as const;
