import {
  createSearchParams,
  Navigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Pages } from "@ll-web/core/router/pages";

export const RedirectInternalProjectSpace = () => {
  const { id, page } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to={{
        pathname: `${Pages.ProjectSpace.replace(":id", id ?? "")}${page ? `/${page}` : ""}`,
        search: `?${createSearchParams(searchParams)}`,
      }}
    />
  );
};
