import { useEffect, useMemo, useState } from "react";

import { throttle } from "lodash-es";

import { useVideoPlayerRef } from "@ll-web/features/videoPlayer/contexts/VideoPlayerRefContext";
import { getPlayer } from "@ll-web/features/videoPlayer/helpers/videoPlayer";
import { ignoreErrors } from "@ll-web/utils/helpers/helpers";

export function useThrottledTime() {
  const { videoPlayerRef, isReady } = useVideoPlayerRef();
  const [throttledTime, setThrottledTime] = useState<number | null>(null);

  const throttledSetter = useMemo(() => {
    return throttle((value: number) => {
      setThrottledTime(value);
    }, 500);
  }, []);

  useEffect(() => {
    if (!isReady) {
      return;
    }
    const player = getPlayer(videoPlayerRef);

    const dispose = player.subscribe(({ currentTime }) => {
      throttledSetter(currentTime);
    });

    return () => {
      setThrottledTime(null);
      throttledSetter.cancel();
      ignoreErrors(() => dispose());
    };
  }, [isReady, videoPlayerRef, throttledSetter]);

  return { throttledTime };
}
