import { CommentsTrackbar } from "@ll-web/features/videoPlayer/comments/components/CommentsTrackbar";
import { CommentsVideoOverlay } from "@ll-web/features/videoPlayer/comments/components/CommentsVideoOverlay";
import { commentsStyleOverrides } from "@ll-web/features/videoPlayer/comments/styles";
import type { CommentsPluginConfig } from "@ll-web/features/videoPlayer/comments/types";
import type { PluginConfigFactory } from "@ll-web/features/videoPlayer/types";

export const withVideoCommentsPlugin: PluginConfigFactory<
  CommentsPluginConfig
> = (config, pluginConfig) => {
  config.playerConfig.hideControlsOnMouseLeave = false;
  config.layoutConfig.children.push(
    <CommentsVideoOverlay pluginConfig={pluginConfig} />,
  );
  config.layoutConfig.slots ??= {};
  config.layoutConfig.slots.beforeTimeSlider = (
    <>
      {config.layoutConfig.slots.beforeTimeSlider}
      <CommentsTrackbar pluginConfig={pluginConfig} />
    </>
  );

  config.styleOverrides.push(commentsStyleOverrides);

  return config;
};
