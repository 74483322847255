import type { SxProps, Theme } from "@mui/material";

const bottomControlsHeight = "92px";

const trackbarContainerSelector = ".vds-controls-group:nth-last-of-type(2)";
const bottomControlsSelector = ".vds-controls-group:nth-last-of-type(1)";

export const commentsStyleOverrides: SxProps<Theme> = {
  ":not([data-fullscreen])": {
    // trackbar and bottom controls
    ".vds-controls": {
      // hide gradient overlay
      background: "none",
      // always show controls, even while idle
      opacity: 1,
      visibility: "visible",
    },
    // make room for controls outside of the player
    paddingBottom: bottomControlsHeight,
  },
  [trackbarContainerSelector]: {
    flexDirection: "column",
  },
  [bottomControlsSelector]: {
    // show menus above comment avatars
    zIndex: `11 !important`,
  },
  ".vds-time-slider": {
    marginBottom: "12px",
    "--media-slider-height": "12px",
  },
};
