import { forwardRef } from "react";

import { TextField, type TextFieldProps } from "@mui/material";

export type SmallWhiteTextFieldProps = Omit<TextFieldProps, "error"> & {
  error?: string;
};

export const SmallWhiteTextField = forwardRef<
  HTMLInputElement,
  SmallWhiteTextFieldProps
>(({ error, ...props }, ref) => {
  return (
    <TextField
      error={!!error}
      helperText={error}
      size="small"
      {...props}
      sx={{
        ".MuiInputBase-root": {
          background: "white",
        },
        ...props.sx,
      }}
      ref={ref}
    />
  );
});

SmallWhiteTextField.displayName = "SmallWhiteTextField";
