export function makeUploadcareThumbnails({
  thumbnailsGroupUuid,
  count,
  duration,
}: {
  thumbnailsGroupUuid: string;
  count: number;
  duration: number; // in seconds
}) {
  const thumbnailDuration = duration / count;
  const thumbnails = Array.from({ length: count }).map((_, index) => ({
    url: `https://ucarecdn.com/${thumbnailsGroupUuid}/nth/${index}/`,
    startTime: thumbnailDuration * index,
    endTime: thumbnailDuration * (index + 1),
  }));

  return thumbnails;
}
