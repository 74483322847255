import { defined } from "@ll-web/utils/types/types";

import type { CreationNotificationType, ReviewNotificationType } from "./types";

type MakeReviewNotificationKeyArgs = {
  projectId: string;
  reviewNotificationType:
    | ReviewNotificationType
    | CreationNotificationType.LegacyFlowProjectCreated;
  resourceId?: string;
};

export const makeReviewNotificationKey = ({
  projectId,
  reviewNotificationType,
  resourceId,
}: MakeReviewNotificationKeyArgs): string => {
  return ["project", projectId, reviewNotificationType, resourceId]
    .filter(defined)
    .join(":");
};

type MakeContractNotificationKeyArgs = {
  contractId: string;
  reviewNotificationType: CreationNotificationType.ContractCreated;
  resourceId?: string;
};

export const makeContractNotificationKey = ({
  contractId,
  reviewNotificationType,
  resourceId,
}: MakeContractNotificationKeyArgs): string => {
  return ["contract", contractId, reviewNotificationType, resourceId]
    .filter(defined)
    .join(":");
};
