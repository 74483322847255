import { memo, useCallback, useMemo } from "react";

import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";

import { MediaProvider } from "@vidstack/react";
import { DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import { isEqual, merge } from "lodash-es";

import { useVideoPlayerRef } from "@ll-web/features/videoPlayer/contexts/VideoPlayerRefContext";
import { useVideoPlayerConfig } from "@ll-web/features/videoPlayer/hooks/useVideoPlayerConfig";
import { StyledMediaPlayer } from "@ll-web/features/videoPlayer/styles";
import type { VideoPlayerConfig } from "@ll-web/features/videoPlayer/types";

export type VideoPlayerProps = VideoPlayerConfig & {
  className?: string;
};

// Remember to wrap the video player in a VideoPlayerRefContextProvider
export const VideoPlayer = memo(
  ({ className, ...config }: VideoPlayerProps) => {
    const { videoPlayerRef, setIsReady } = useVideoPlayerRef();
    const { playerConfig, layoutConfig, styleOverrides } =
      useVideoPlayerConfig(config);
    useVideoPlayerConfig(config);
    const onCanPlay = useCallback(() => setIsReady(true), [setIsReady]);

    const sx = useMemo(() => {
      return merge({}, ...styleOverrides);
    }, [styleOverrides]);

    return (
      <StyledMediaPlayer
        {...playerConfig}
        ref={videoPlayerRef}
        className={className}
        onCanPlay={onCanPlay}
        sx={sx}
      >
        <MediaProvider />
        <DefaultVideoLayout {...layoutConfig} />
      </StyledMediaPlayer>
    );
  },
  isEqual,
);
VideoPlayer.displayName = "VideoPlayer";
