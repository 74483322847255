import { useEffect, type ReactNode } from "react";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { Pages } from "@ll-web/core/router/pages";
import { useLogOut } from "@ll-web/features/auth/hooks/useLogOut";
import { useUser } from "@ll-web/features/auth/hooks/useUser";
import {
  ExternalAccountTypes,
  InternalAccountTypes,
  type AccountType,
} from "@ll-web/features/auth/types";
import { makeNextParamForCurrent } from "@ll-web/utils/helpers/navigation";

interface IsLoggedInGuardProps {
  accountTypes?: AccountType[];
  emailHint?: string;
  children?: ReactNode;
  redirectPage?: Pages.Login | Pages.Register;
}

export const IsLoggedInGuard = ({
  children,
  emailHint,
  accountTypes = [],
  redirectPage = Pages.Login,
}: IsLoggedInGuardProps) => {
  const { isLoading, isAuthorized, activeUser } = useUser();
  const logOut = useLogOut({ activeUser });

  const isAllowed =
    isAuthorized &&
    (accountTypes?.length
      ? accountTypes.includes(activeUser.accountType)
      : true);

  useEffect(() => {
    if (!isLoading && !isAllowed) {
      logOut({
        redirect: `${redirectPage}?${makeNextParamForCurrent()}${
          emailHint ? `&${new URLSearchParams({ email: emailHint })}` : ""
        }`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAllowed]);

  if (!isAllowed) {
    return <SuspenseLoader />;
  }

  return children;
};

export const IsInternalLoggedInGuard = (
  props: Omit<IsLoggedInGuardProps, "accountTypes">,
) => {
  return <IsLoggedInGuard {...props} accountTypes={InternalAccountTypes} />;
};

export const IsExternalLoggedInGuard = (
  props: Omit<IsLoggedInGuardProps, "accountTypes">,
) => {
  return <IsLoggedInGuard {...props} accountTypes={ExternalAccountTypes} />;
};
