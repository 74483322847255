import { useMutation, useQueryClient } from "@tanstack/react-query";

import type { VisualsItem } from "@ll-web/features/llm/prompts/Visuals/types";
import { projectsService } from "@ll-web/features/projects/async/ProjectsService";
import { ProjectsQueries } from "@ll-web/features/projects/async/useProjectsQueries";
import type { ProjectCharacter } from "@ll-web/features/projects/types";
import { ProjectWizardQueries } from "@ll-web/features/projectWizard/async/projectWizardQueries";
import { projectWizardService } from "@ll-web/features/projectWizard/async/ProjectWizardService";
import {
  OutputArchiveSourceEnum,
  type CharactersOutputArchive,
  type ProjectCharacterAndVideoIdParams,
  type ProjectIdAndVideoIdParams,
  type ScriptedScriptOutputArchive,
  type ScriptedVideoSummaryOutputArchive,
  type UpdatedOutputArchivePayload,
  type VideoSummaryOutputArchive,
  type VoiceoverOutputArchive,
} from "@ll-web/features/projectWizard/types";
import { createMutationHook } from "@ll-web/utils/factories/createMutationHook";
import { isBadRequestError } from "@ll-web/utils/helpers/helpers";
import { assertDefined } from "@ll-web/utils/types/types";

export const useSaveInterviewOutputMutation = createMutationHook(
  projectWizardService.saveInterviewOutput.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (data, param) => {
      queryClient.setQueriesData(
        {
          queryKey: ProjectWizardQueries.getLatestInterviewOutputByInterviewId({
            projectId: param.projectId,
            interviewId: param.interviewId,
          }).queryKey,
        },
        data,
      );
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getInterviewsOutputs({
          projectId: param.projectId,
          interviewId: param.interviewId,
        }).queryKey,
      });
    },
  }),
);

export const useUpdateInterviewOutputMutation = createMutationHook(
  projectWizardService.updateInterviewOutput.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getLatestInterviewOutputByInterviewId({
          projectId: params.projectId,
          interviewId: params.interviewId ?? "",
        }).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getInterviewsOutputs({
          projectId: params.projectId,
          interviewId: params.interviewId ?? "",
        }).queryKey,
      });
    },
  }),
);

export const useAddInterviewMutation = createMutationHook(
  projectWizardService.addInterview.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getInterviews({
          projectId: params.projectId,
        }).queryKey,
      });
    },
  }),
);

export const useUpdateInterviewMutation = createMutationHook(
  projectWizardService.updateInterview.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.refetchQueries({
        queryKey: ProjectWizardQueries.getInterviews({
          projectId: params.projectId,
        }).queryKey,
      });
    },
  }),
);

export const useRemoveInterviewMutation = createMutationHook(
  projectWizardService.removeInterview.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getInterviews({
          projectId: params.projectId,
        }).queryKey,
      });
    },
  }),
);

export const useSaveScheduleOutputMutation = createMutationHook(
  projectWizardService.saveScheduleOutput.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (data, params) => {
      queryClient.setQueriesData(
        {
          queryKey: ProjectWizardQueries.getLatestScheduleOutputByProjectId({
            projectId: params.projectId,
            productionDayId: params.productionDayId,
          }).queryKey,
        },
        data,
      );
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getScheduleOutputs({
          projectId: params.projectId,
          productionDayId: params.productionDayId,
        }).queryKey,
      });
    },
  }),
);

export const useUpdateScheduleOutputMutation = createMutationHook(
  projectWizardService.updateScheduleOutput.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getLatestScheduleOutputByProjectId({
          projectId: params.projectId,
          productionDayId: params.productionDayId,
        }).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getScheduleOutputs({
          projectId: params.projectId,
          productionDayId: params.productionDayId,
        }).queryKey,
      });
    },
  }),
);

export const useRemoveIntervieweeFromProductionDaysMutation =
  createMutationHook(
    projectWizardService.removeIntervieweesFromProductionDays.bind(
      projectWizardService,
    ),
    (queryClient) => ({
      onSuccess: (_, params) => {
        queryClient.invalidateQueries({
          queryKey: ProjectsQueries.getProductionDaysByProjectId({
            id: params.projectId,
          }).queryKey,
        });
      },
    }),
  );

export const useUpdateVoiceoverInputMutation = createMutationHook(
  projectWizardService.updateVoiceoverInput.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.refetchQueries({
        queryKey: ProjectWizardQueries.getVoiceoverInputByVideoId({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });
    },
  }),
);

export function useSaveVoiceoverOutputMutation() {
  const queryClient = useQueryClient();
  const saveVisualsOutputMutation = useSaveVisualsOutputMutation();

  return useMutation({
    mutationFn:
      projectWizardService.saveVoiceoverOutput.bind(projectWizardService),
    async onSuccess(
      data: VoiceoverOutputArchive,
      params: VoiceoverOutputArchive & ProjectIdAndVideoIdParams,
    ) {
      queryClient.setQueriesData(
        {
          queryKey: ProjectWizardQueries.getLatestVoiceoverByVideoId({
            projectId: params.projectId,
            videoId: params.videoId,
          }).queryKey,
        },
        data,
      );
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getVoiceoverOutputs({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });

      // Update the latest visuals update to use the new voiceover
      // by creating a new history entry with updated voiceoverOutputId
      const latestVisualsOutput =
        await projectWizardService.getLatestVisualsOutputByVideoId({
          projectId: params.projectId,
          videoId: params.videoId,
        });

      if (!latestVisualsOutput?.voiceoverOutputId) {
        return;
      }

      assertDefined(data.id, "data.id");
      await saveVisualsOutputMutation.mutateAsync({
        source: OutputArchiveSourceEnum.DependencyUpdate,
        createdAt: data.createdAt,
        author: data.author,
        output: latestVisualsOutput.output
          .concat(
            new Array(data.output.length).fill({
              onScreenTitle: "",
              visualDescription: "",
            } satisfies VisualsItem),
          )
          .slice(0, data.output.length)
          .map((output, i) => ({
            ...output,
            // The "voiceover" in visuals output is no longer used but let's update it just in case
            voiceover: data.output[i]?.voiceover ?? "",
          })),
        previousId: latestVisualsOutput.id,
        misc: latestVisualsOutput.misc,
        projectId: params.projectId,
        videoId: params.videoId,
        voiceoverOutputId: data.id,
      });
    },
  });
}

export function useUpdateVoiceoverOutputMutation() {
  const queryClient = useQueryClient();
  const updateVisualsOutputMutation = useUpdateVisualsOutputMutation();

  return useMutation({
    mutationFn:
      projectWizardService.updateVoiceoverOutput.bind(projectWizardService),
    async onSuccess(
      data: VoiceoverOutputArchive,
      params: UpdatedOutputArchivePayload<VoiceoverOutputArchive> &
        ProjectIdAndVideoIdParams,
    ) {
      queryClient.setQueriesData(
        {
          queryKey: ProjectWizardQueries.getLatestVoiceoverByVideoId({
            projectId: params.projectId,
            videoId: params.videoId,
          }).queryKey,
        },
        data,
      );
      if (data.id) {
        queryClient.invalidateQueries({
          queryKey: ProjectWizardQueries.getVoiceoverOutputById({
            voiceoverOutputId: data.id,
            projectId: params.projectId,
          }).queryKey,
        });
      }
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getVoiceoverOutputs({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });

      // Update the latest visuals update to use the new voiceover
      const latestVisualsOutput =
        await projectWizardService.getLatestVisualsOutputByVideoId({
          projectId: params.projectId,
          videoId: params.videoId,
        });

      if (!latestVisualsOutput?.voiceoverOutputId) {
        return;
      }

      assertDefined(data.id, "data.id");
      await updateVisualsOutputMutation.mutateAsync({
        id: latestVisualsOutput.id,
        output: latestVisualsOutput.output
          .concat(
            new Array(data.output.length).fill({
              onScreenTitle: "",
              visualDescription: "",
            } satisfies VisualsItem),
          )
          .slice(0, data.output.length)
          .map((output, i) => ({
            ...output,
            // The "voiceover" in visuals output is no longer used but let's update it just in case
            voiceover: data.output[i]?.voiceover ?? "",
          })),
        projectId: params.projectId,
        videoId: params.videoId,
      });
    },
  });
}

export const useUpdateVisualsInputsMutation = createMutationHook(
  projectWizardService.updateVisualsInputs.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.refetchQueries({
        queryKey: ProjectWizardQueries.getVisualsInputsByVideoId({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });
    },
  }),
);

export const useSaveVisualsOutputMutation = createMutationHook(
  projectWizardService.saveVisualsOutput.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (data, params) => {
      queryClient.setQueriesData(
        {
          queryKey: ProjectWizardQueries.getLatestVisualsOutputByVideoId({
            projectId: params.projectId,
            videoId: params.videoId,
          }).queryKey,
        },
        data,
      );
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getVisualsOutputs({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });
    },
  }),
);

export const useUpdateVisualsOutputMutation = createMutationHook(
  projectWizardService.updateVisualsOutput.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getLatestVisualsOutputByVideoId({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getVisualsOutputs({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });
    },
  }),
);

export const useSaveScriptedScriptOutputMutation = createMutationHook(
  projectWizardService.saveScriptedScriptOutput.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (data, params) => {
      queryClient.setQueriesData(
        {
          queryKey: ProjectWizardQueries.getLatestScriptedScriptOutputByVideoId(
            {
              projectId: params.projectId,
              videoId: params.videoId,
            },
          ).queryKey,
        },
        data,
      );
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getScriptedScriptOutputs({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });
    },
  }),
);

export const useUpdateScriptedScriptOutputMutation = createMutationHook(
  (
    args: UpdatedOutputArchivePayload<ScriptedScriptOutputArchive> &
      ProjectIdAndVideoIdParams,
  ) => projectWizardService.updateScriptedScriptOutput(args),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getLatestScriptedScriptOutputByVideoId({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getScriptedScriptOutputs({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });
    },
  }),
);

export const useSaveCharactersOutputMutation = createMutationHook(
  projectWizardService.saveCharactersOutput.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (data, params) => {
      queryClient.setQueriesData(
        {
          queryKey:
            ProjectWizardQueries.getLatestCharacterOutputByCharacterAndVideoId({
              projectId: params.projectId,
              videoId: params.videoId,
              characterId: params.characterId,
            }).queryKey,
        },
        data,
      );
      queryClient.resetQueries({
        queryKey: ProjectWizardQueries.getCharacterCombinedDescription({
          projectId: params.projectId,
          character: {
            id: params.characterId,
          } as ProjectCharacter,
        }).queryKey.slice(0, -1),
      });
    },
  }),
);

export const useUpdateCharactersOutputMutation = createMutationHook(
  (
    args: UpdatedOutputArchivePayload<CharactersOutputArchive> &
      ProjectCharacterAndVideoIdParams,
  ) => projectWizardService.updateCharactersOutput(args),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey:
          ProjectWizardQueries.getLatestCharacterOutputByCharacterAndVideoId({
            projectId: params.projectId,
            videoId: params.videoId,
            characterId: params.characterId,
          }).queryKey,
      });
      queryClient.resetQueries({
        queryKey: ProjectWizardQueries.getCharacterCombinedDescription({
          projectId: params.projectId,
          character: {
            id: params.characterId,
          } as ProjectCharacter,
        }).queryKey.slice(0, -1),
      });
    },
  }),
);

export const useSubmitForClientReviewMutation = createMutationHook(
  projectsService.submitProjectForReview.bind(projectsService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ProjectsQueries.getById({ id: params.projectId }).queryKey,
      });
    },
  }),
);

export const useUpdateCharacterMutation = createMutationHook(
  projectWizardService.updateCharacter.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.refetchQueries({
        queryKey: ProjectsQueries.getCharactersByProjectId({
          id: params.projectId,
        }).queryKey,
      });
    },
  }),
);

// eslint-disable-next-line import/no-unused-modules
export const useAddCharacterMutation = createMutationHook(
  projectWizardService.addCharacter.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.refetchQueries({
        queryKey: ProjectsQueries.getCharactersByProjectId({
          id: params.projectId,
        }).queryKey,
      });
    },
  }),
);

// eslint-disable-next-line import/no-unused-modules
export const useRemoveCharacterMutation = createMutationHook(
  projectWizardService.removeCharacter.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.refetchQueries({
        queryKey: ProjectsQueries.getCharactersByProjectId({
          id: params.projectId,
        }).queryKey,
      });
    },
  }),
);

export const useSaveVideoSummaryOutputMutation = createMutationHook(
  projectWizardService.saveVideoSummaryOutput.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (data, params) => {
      queryClient.setQueriesData(
        {
          queryKey: ProjectWizardQueries.getLatestVideoSummaryByVideoId({
            projectId: params.projectId,
            videoId: params.videoId,
          }).queryKey,
        },
        data,
      );
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getVideoSummaryOutputs({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });
    },
  }),
);

export const useUpdateVideoSummaryOutputMutation = createMutationHook(
  (
    args: UpdatedOutputArchivePayload<
      VideoSummaryOutputArchive | ScriptedVideoSummaryOutputArchive
    > &
      ProjectIdAndVideoIdParams,
  ) => projectWizardService.updateVideoSummaryOutput(args),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getLatestVideoSummaryByVideoId({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getVideoSummaryOutputs({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });
    },
  }),
);

export const useAddStoryboardNoteMutation = createMutationHook(
  projectWizardService.addStoryboardNote.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ProjectWizardQueries.getStoryboardNotesByVideoId({
          projectId: params.projectId,
          videoId: params.videoId,
        }).queryKey,
      });
    },
  }),
);

export const useGetLookAndFeelImageMutation = createMutationHook(
  projectWizardService.getLookAndFeelImage.bind(projectWizardService),
  () => ({
    retry: (failureCount, error) => {
      if (isBadRequestError(error)) {
        return false;
      }

      return failureCount <= 3;
    },
  }),
);

export const useGetWardrobeImageMutation = createMutationHook(
  projectWizardService.getWardrobeImage.bind(projectWizardService),
  () => ({
    retry: (failureCount, error) => {
      if (isBadRequestError(error)) {
        return false;
      }

      return failureCount <= 3;
    },
  }),
);

export const useRequestReview = createMutationHook(
  projectWizardService.requestReview.bind(projectWizardService),
);

// eslint-disable-next-line import/no-unused-modules
export const useApproveReview = createMutationHook(
  projectWizardService.approveReview.bind(projectWizardService),
);

export const useUpdateClientFirstViewedAtMutation = createMutationHook(
  projectWizardService.updateClientFirstViewedAt.bind(projectWizardService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ProjectsQueries.getById({
          id: params.projectId,
        }).queryKey,
      });
    },
  }),
);

export const useUpdateClientFirstFinishedPreproductionAtMutation =
  createMutationHook(
    projectWizardService.updateClientFirstFinishedPreproductionAt.bind(
      projectWizardService,
    ),
    (queryClient) => ({
      onSuccess: (_, params) => {
        queryClient.invalidateQueries({
          queryKey: ProjectsQueries.getById({
            id: params.projectId,
          }).queryKey,
        });
      },
    }),
  );

export const useUpdateSuccessfullyFirstFinalizedPreproductionAtMutation =
  createMutationHook(
    projectWizardService.updateSuccessfullyFirstFinalizedPreproductionAt.bind(
      projectWizardService,
    ),
    (queryClient) => ({
      onSuccess: (_, params) => {
        queryClient.invalidateQueries({
          queryKey: ProjectsQueries.getById({
            id: params.projectId,
          }).queryKey,
        });
      },
    }),
  );
