import type { AccountType } from "@ll-web/features/auth/types";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { WizardFlowType } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";

import { ReviewNotificationType } from "./types";

type WizardReviewNotificationTypeProps = {
  flowType: WizardFlowType;
  user: { accountType?: AccountType };
};

export const wizardReviewNotificationType = ({
  flowType,
  user,
}: WizardReviewNotificationTypeProps): ReviewNotificationType => {
  switch (flowType) {
    case WizardFlowType.CreativeBrief:
      return ReviewNotificationType.CreativeBriefInternal;
    case WizardFlowType.Generate:
      return isUserInternal(user)
        ? ReviewNotificationType.WizardGenerateInternal
        : ReviewNotificationType.WizardGenerateExternal;
    case WizardFlowType.Edit:
      return isUserInternal(user)
        ? ReviewNotificationType.CreativeDeckEditInternal
        : ReviewNotificationType.CreativeDeckEditExternal;
  }
};
