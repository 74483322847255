import { useParams, useSearchParams } from "react-router-dom";

import { useGetProjectById } from "@ll-web/features/projects/async/useProjectsQueries";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";

export const useActiveProject = (): {
  activeProject: ProjectWithDeliverables;
} => {
  const pathParams = useParams();
  const [queryParms] = useSearchParams();
  const projectId = pathParams.id ?? queryParms.get("projectId") ?? undefined;
  const query = useGetProjectById({
    id: projectId,
  });

  if (!query.isSuccess) {
    throw new Error(
      `Active Project is pending, missing active project id or guard in '${window.location.pathname}'`,
      {
        cause: query.error,
      },
    );
  }

  return { activeProject: query.data };
};
