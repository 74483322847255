import { Box, type BoxProps } from "@mui/material";

type ThreadLineProps = BoxProps;

export const ThreadLine = (props: ThreadLineProps) => {
  return (
    <Box
      {...props}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "400px",
        width: "2px",
        background: (theme) => theme.palette.divider,
        zIndex: 0,
        ...props.sx,
      }}
    />
  );
};
