import { useCallback } from "react";

import { type SxProps, type Theme } from "@mui/material";

import { CommentThread } from "@ll-web/features/projectComments/components/CommentThread";
import { ThreadBubble } from "@ll-web/features/videoPlayer/comments/components/ThreadBubble";
import { useGetThreadIndex } from "@ll-web/features/videoPlayer/comments/hooks/useGetThreadCommentCount";
import type { $TSFixMe } from "@ll-web/utils/types/types";

type VideoThreadProps = {
  projectId: string;
  videoId: string;
  threadId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  sx: SxProps<Theme>;
};

export const VideoThread = ({
  projectId,
  videoId,
  threadId,
  isOpen,
  setIsOpen,
  sx,
}: VideoThreadProps) => {
  // this query should be already cached
  const { index } = useGetThreadIndex({
    threadId,
    projectId,
    videoId,
  });

  const handleCancel = useCallback(async () => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <ThreadBubble
      threadId={threadId}
      index={index ?? 1}
      sx={sx}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <CommentThread
        editMode="viewComment"
        commentsConfig={{} as $TSFixMe}
        threadId={threadId}
        onCancel={handleCancel}
        onAddReply={async (...args) => console.log("onAddReply", ...args)}
        /// TODO: comments management
        onUpdate={async (...args) => console.log("onUpdate", ...args)}
        onResolve={async (...args) => console.log("onResolve", ...args)}
        onReopen={async (...args) => console.log("onReopen", ...args)}
        onRemoveComment={async (...args) =>
          console.log("onRemoveComment", ...args)
        }
        onEditStart={async (...args) => console.log("onEditStart", ...args)}
        onEditFinish={async (...args) => console.log("onEditFinish", ...args)}
      />
    </ThreadBubble>
  );
};
