import { WizardSteps } from "@ll-web/features/projectWizard/consts/wizardSteps";

export enum ProjectSpacePages {
  Overview = "overview",
  Timeline = "timeline",
  ClientAssets = "client-assets",
  Intake = "intake",
  DeliverableSummary = "deliverable-summary",
  CreativeDeck = "creative-deck",
  CallSheets = "call-sheets",
}

export enum ProjectSpaceLinks {
  CreativeBriefInput = "creative-brief-input",
}

export enum ProjectSpaceEditPages {
  Schedule = WizardSteps.Schedule,
  Interview = WizardSteps.Interviews,
}
