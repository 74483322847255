import { useRef, type ReactNode } from "react";

import {
  alpha,
  Box,
  Popover,
  Stack,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";

import { useHoveredCommentContext } from "@ll-web/features/projectComments/contexts/HoveredCommentContext";
import { defined } from "@ll-web/utils/types/types";

type ThreadBubbleProps = {
  threadId: string | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  sx: SxProps<Theme>;
  children: ReactNode;
  index: number | null;
};

export const ThreadBubble = ({
  threadId,
  isOpen,
  setIsOpen,
  sx,
  children,
  index,
}: ThreadBubbleProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isHovered } = useHoveredCommentContext({
    threadId,
  });

  const hoveredStyles: SxProps<Theme> = {
    zIndex: 1,
    boxShadow: (theme) =>
      `0 0 0 2px ${alpha(theme.palette.secondary.main, 0.8)}`,
  };

  return (
    <>
      <Box
        className="comments-thread-bubble-anchor"
        sx={{
          ...sx,
          width: 0,
          height: 0,
        }}
        ref={ref}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            cursor: "pointer",
            minWidth: 32,
            height: 32,
            aspectRatio: "1/1",
            transform: "translate(-50%, -50%)",
            boxShadow: (theme) =>
              `0 0 0 1px ${alpha(theme.palette.common.white, 0.5)}`,
            borderRadius: "50%",
            ...(isOpen
              ? {
                  background: (theme) => theme.palette.primary.main,
                }
              : {
                  background: (theme) => theme.palette.common.black,
                }),
            ...(isHovered && hoveredStyles),
            ":hover": hoveredStyles,
            transition: (theme) =>
              theme.transitions.create(["box-shadow", "background"]),
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Typography variant="subtitle1" color="white">
            {defined(index) ? index + 1 : "＋"}
          </Typography>
        </Stack>
      </Box>

      <Popover
        anchorEl={ref.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: -16,
          horizontal: 32,
        }}
      >
        {children}
      </Popover>
    </>
  );
};
