export enum ReviewNotificationType {
  CreativeBriefInternal = "creativeBrief:internal",
  // Creative Brief is dedicated to Internals (Account Executives) and not available for externals
  WizardGenerateInternal = "wizardGenerate:internal",
  WizardGenerateExternal = "wizardGenerate:external",
  CreativeDeckEditInternal = "creativeDeckEdit:internal",
  CreativeDeckEditExternal = "creativeDeckEdit:external",
}

export enum CreationNotificationType {
  ContractCreated = "contractCreated",
  LegacyFlowProjectCreated = "projectCreated",
}
