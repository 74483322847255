import { useCallback, useState } from "react";

import {
  Box,
  Skeleton,
  Stack,
  Typography,
  type StackProps,
} from "@mui/material";
import dayjs from "dayjs";
import { upperFirst } from "lodash-es";
import * as yup from "yup";

import { TextWithEllipsis } from "@ll-web/components/TextWithEllipsis/TextWithEllipsis";
import { UserAvatarWithPopover } from "@ll-web/components/User/UserAvatarWithPopover";
import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import type {
  CommentMessageType,
  ProjectComment,
  ProjectCommentAnalyticsMetadata,
} from "@ll-web/features/projectComments/types";
import type {
  TextEditorCommentsPluginConfig,
  TextEditorEditCommentMode,
} from "@ll-web/features/textEditor/comments/types";
import { useGetUserById } from "@ll-web/features/users/async/useUsersQueries";
import { mapUnknownToDayjs } from "@ll-web/utils/helpers/date";
import { formatSecondsDurationWithColon } from "@ll-web/utils/helpers/duration";
import { useTypedSearchParams } from "@ll-web/utils/hooks/useTypedSearchParams";
import { assertDefined, defined } from "@ll-web/utils/types/types";

import {
  CommentEditMessage,
  type CommentMessageValues,
} from "./CommentEditMessage";
import { CommentMoreActionsMenu } from "./CommentMoreActionsMenu";
import { CommentQuote } from "./CommentQuote";
import { CommentResolveButton } from "./CommentResolveButton";
import { ThreadLine } from "./ThreadLine";

const commentActionClassName = "commentActionClassName";

type CommentMessageProps = Omit<StackProps, "onSubmit" | "onRemove"> & {
  comment?: ProjectComment | null;
  editMode: TextEditorEditCommentMode;
  isReadOnly?: boolean;
  commentsConfig?: TextEditorCommentsPluginConfig;
  onSubmit: (comment: CommentMessageType) => Promise<void>;
  onRemove: ({ id }: { id: string }) => Promise<void>;
  onToggleResolve?: ({ id }: { id: string }) => Promise<void>;
  isTogglingResolve?: boolean;
  onEditStart: () => void;
  onEditFinish: () => void;
  onInputChange?: (value: string) => void;
  showThreadLine?: boolean;
  showQuote?: boolean;
  alwaysShowMenu?: boolean;
};

export const CommentMessage = ({
  comment,
  editMode,
  isReadOnly,
  commentsConfig,
  onSubmit,
  onRemove,
  onToggleResolve,
  isTogglingResolve,
  onEditStart,
  onEditFinish,
  onInputChange,
  showThreadLine,
  showQuote,
  alwaysShowMenu,
  ...props
}: CommentMessageProps) => {
  const { activeUser } = useActiveUser();
  const { updateParams } = useTypedSearchParams(
    yup.object({
      commentId: yup.string().optional(),
      t: yup.number().optional(),
    }),
    undefined,
    { stripUnknown: false },
  );
  const [isEditing, setIsEditing] = useState(false);

  const message = comment?.message;
  const userId = comment?.userId;
  const createdAt = comment?.createdAt;
  const id = comment?.id;
  const timestamp = comment?.target.timestamp;

  const userQuery = useGetUserById(
    { userId },
    { meta: { supressErrorToast: true }, enabled: !!userId },
  );

  const isActiveUser = activeUser?._id === userId;
  const avatarSize = 32;
  const zIndex = 1;

  const dayjsDate = mapUnknownToDayjs(createdAt);
  const isJustNow = dayjsDate?.isAfter(dayjs().subtract(50, "seconds"));
  const dateText = upperFirst(
    isJustNow ? "just now" : dayjsDate?.fromNow() ?? "",
  );

  const handleEdit = useCallback(() => {
    onEditStart();
    setIsEditing(true);
  }, [onEditStart]);

  const handleCancel = useCallback(() => {
    onEditFinish();
    setIsEditing(false);
  }, [onEditFinish]);

  const handleSubmit = useCallback(
    async (values: CommentMessageValues) => {
      if (!comment) {
        return;
      }

      await onSubmit({
        ...comment,
        ...values,
      });
      setIsEditing(false);
      onEditFinish();
    },
    [comment, onSubmit, onEditFinish],
  );

  const handleResolve = useCallback(async () => {
    assertDefined(comment?.id);
    assertDefined(onToggleResolve);

    await onToggleResolve({ id: comment.id });
  }, [comment, onToggleResolve]);

  const handleRemove = useCallback(async () => {
    assertDefined(comment?.id);

    await onRemove({ id: comment.id });
    setIsEditing(false);
  }, [comment, onRemove]);

  const handleRemoveCancel = useCallback(() => {
    if (commentsConfig && "metadata" in commentsConfig) {
      activityTracker.log({
        type: ActivityType.WizardOutputCanceledRemoveComment,
        metadata: commentsConfig.metadata
          .analyticsMetadata as ProjectCommentAnalyticsMetadata,
      });
    }
  }, [commentsConfig]);

  const handleTimestampClick = useCallback(() => {
    assertDefined(timestamp, "timestamp");
    assertDefined(id, "id");
    // TODO: add proper callback
    updateParams({ commentId: id, t: timestamp });
  }, [updateParams, id, timestamp]);

  return (
    <Stack
      direction="row"
      gap={1}
      {...props}
      sx={{ pb: 1, position: "relative", overflow: "hidden", ...props.sx }}
    >
      {userQuery.data ? (
        <UserAvatarWithPopover
          size={avatarSize}
          user={userQuery.data}
          sx={{ zIndex }}
        />
      ) : (
        <Skeleton
          variant="circular"
          sx={{
            minWidth: avatarSize,
            width: avatarSize,
            height: avatarSize,
            zIndex,
          }}
        />
      )}

      <Stack
        sx={{
          flexGrow: 1,
          zIndex,
          ...(alwaysShowMenu
            ? {}
            : {
                [`:hover .${commentActionClassName}`]: {
                  opacity: 1,
                },
                [`& .${commentActionClassName}`]: {
                  opacity: 0,
                  transition: (theme) => theme.transitions.create("opacity"),
                },
              }),
        }}
      >
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Stack direction="row" gap={0.5} alignItems="center">
              {userQuery.data ? (
                <TextWithEllipsis maxWidth={140} variant="subtitle2">
                  {isActiveUser
                    ? "You"
                    : `${userQuery.data.firstName} ${userQuery.data.lastName}`}
                </TextWithEllipsis>
              ) : (
                <Skeleton variant="text" sx={{ width: 80 }} />
              )}

              <TextWithEllipsis
                maxWidth={120}
                variant="body2"
                color="text.disabled"
              >
                {dateText}
              </TextWithEllipsis>
            </Stack>
          </Stack>
          <Stack direction="row" gap={0.5}>
            {!isReadOnly && onToggleResolve && (
              <CommentResolveButton
                isResolved={!!comment?.isResolved}
                onClick={handleResolve}
                disabled={isTogglingResolve}
                className={commentActionClassName}
              />
            )}

            {!isReadOnly && isActiveUser && (
              <CommentMoreActionsMenu
                onEdit={handleEdit}
                commentsConfig={commentsConfig}
                onRemove={handleRemove}
                onRemoveCancel={handleRemoveCancel}
                sx={{ p: 0.5 }}
                disabled={!comment}
                className={commentActionClassName}
                isEditable={!comment?.isResolved}
              />
            )}
          </Stack>
        </Stack>

        {showQuote && comment?.quote && (
          <CommentQuote>{comment.quote}</CommentQuote>
        )}

        {defined(timestamp) && (
          <Typography
            variant="subtitle2"
            sx={{
              color: (theme) => theme.palette.primary.main,
              cursor: "pointer",
              width: "max-content",
              // TODO: styling
            }}
            onClick={handleTimestampClick}
          >
            {formatSecondsDurationWithColon(timestamp)}
          </Typography>
        )}

        <Box>
          {isEditing ? (
            <CommentEditMessage
              message={message}
              editMode={editMode}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              avatarSize={avatarSize}
              withAvatar={false}
              onInputChange={onInputChange}
            />
          ) : (
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "pre-line",
                overflowWrap: "anywhere",
                py: 1,
              }}
            >
              {message}
            </Typography>
          )}
        </Box>
      </Stack>

      {!!userQuery.data && showThreadLine && (
        <ThreadLine
          sx={{ marginLeft: `${avatarSize / 2}px`, zIndex: zIndex - 1 }}
        />
      )}
    </Stack>
  );
};
