import { memo, useCallback, useMemo, useState } from "react";

import { Box } from "@mui/material";
import { useMediaRemote, useMediaStore } from "@vidstack/react";
import * as yup from "yup";

import type { CommentMessageValues } from "@ll-web/features/projectComments/components/CommentEditMessage";
import { ProjectCommentSourceEnum } from "@ll-web/features/projectComments/enums";
import { useProjectComments } from "@ll-web/features/projectComments/hooks/useProjectComments";
import type {
  ProjectComment,
  ProjectCommentMetadata,
} from "@ll-web/features/projectComments/types";
import { CommentsOverlayClickTarget } from "@ll-web/features/videoPlayer/comments/components/CommentsOverlayClickTarget";
import { NewVideoThread } from "@ll-web/features/videoPlayer/comments/components/NewVideoThread";
import { VideoThread } from "@ll-web/features/videoPlayer/comments/components/VideoThread";
import { useGetOnVideoThreads } from "@ll-web/features/videoPlayer/comments/hooks/useGetOnVideoThreads";
import type { CommentsPluginConfig } from "@ll-web/features/videoPlayer/comments/types";
import { useVideoPlayerRef } from "@ll-web/features/videoPlayer/contexts/VideoPlayerRefContext";
import { getPlayer } from "@ll-web/features/videoPlayer/helpers/videoPlayer";
import { useThrottledTime } from "@ll-web/features/videoPlayer/hooks/useThrottledTime";
import { useTypedSearchParams } from "@ll-web/utils/hooks/useTypedSearchParams";
import { assertDefined } from "@ll-web/utils/types/types";

type CommentsVideoOverlayProps = {
  pluginConfig: CommentsPluginConfig;
};

export const CommentsVideoOverlay = memo(
  ({ pluginConfig }: CommentsVideoOverlayProps) => {
    const { videoPlayerRef } = useVideoPlayerRef();
    const { canPlay } = useMediaStore(videoPlayerRef);
    const mediaRemote = useMediaRemote(videoPlayerRef);
    const { throttledTime } = useThrottledTime();
    const { params, updateParams } = useTypedSearchParams(
      yup.object({
        commentId: yup.string().optional(),
      }),
      undefined,
      { stripUnknown: false },
    );
    const activeCommentId = params.commentId;

    const [newThread, setNewThread] = useState<Required<
      Pick<ProjectComment["target"], "position">
    > | null>(null);

    const { threads } = useGetOnVideoThreads({
      projectId: pluginConfig.projectId,
      videoId: pluginConfig.videoId,
      timestamp: throttledTime ?? Infinity,
    });

    const metadata = useMemo<ProjectCommentMetadata>(
      () => ({
        analyticsMetadata: {
          projectId: pluginConfig.projectId,
          // TODO: analytics
          projectName: "",
          videoStyle: "",
          page: "Video Review",
        },
        target: {
          source: ProjectCommentSourceEnum.VideoReview,
          videoId: pluginConfig.videoId,
        },
      }),
      [pluginConfig],
    );
    const { onAdd } = useProjectComments({
      metadata,
    });

    const handleStartAddThread = useCallback(
      ({ position }: { position: [number, number] }) => {
        mediaRemote.pause();
        setNewThread({
          position,
        });
        updateParams({ commentId: undefined });
      },
      [updateParams, mediaRemote],
    );

    const handleAddThread = useCallback(
      async ({ message }: CommentMessageValues) => {
        assertDefined(newThread, "draftComment");
        const player = getPlayer(videoPlayerRef);
        const timestamp = player.currentTime;
        await onAdd({
          message,
          target: {
            position: newThread.position,
            timestamp,
          },
        });
        setNewThread(null);
      },
      [onAdd, videoPlayerRef, newThread],
    );

    const handleCancelAddThread = useCallback(() => {
      setNewThread(null);
    }, []);

    if (!canPlay) {
      return null;
    }

    return (
      <Box
        sx={{
          position: "absolute",
          inset: 0,
        }}
        className="comments-overlay"
      >
        <CommentsOverlayClickTarget onAddTrigger={handleStartAddThread} />

        {threads?.map((thread) => (
          <VideoThread
            key={thread.id}
            projectId={pluginConfig.projectId}
            videoId={pluginConfig.videoId}
            threadId={thread.id}
            isOpen={activeCommentId === thread.id}
            setIsOpen={(value) =>
              updateParams({ commentId: value ? thread.id : undefined })
            }
            sx={{
              position: "absolute",
              left: `${thread.target.position![0] * 100}%`,
              top: `${thread.target.position![1] * 100}%`,
            }}
          />
        ))}

        {newThread && (
          <NewVideoThread
            cancel={handleCancelAddThread}
            onAddThread={handleAddThread}
            sx={{
              position: "absolute",
              left: `${newThread.position[0] * 100}%`,
              top: `${newThread.position[1] * 100}%`,
            }}
          />
        )}
      </Box>
    );
  },
);
CommentsVideoOverlay.displayName = "CommentsVideoOverlay";
