import { useEffect, useMemo, useRef } from "react";

import { Stack, Typography } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";

import {
  useGetCommentById,
  useGetCommentsByThreadId,
} from "@ll-web/features/projectComments/async/useProjectCommentsQueries";
import { CommentEditMessage } from "@ll-web/features/projectComments/components/CommentEditMessage";
import { CommentMessage } from "@ll-web/features/projectComments/components/CommentMessage";
import type { CommentMessageType } from "@ll-web/features/projectComments/types";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";
import type {
  TextEditorCommentsPluginConfig,
  TextEditorEditCommentMode,
} from "@ll-web/features/textEditor/comments/types";
import { defined } from "@ll-web/utils/types/types";

const ThrowUnavailableThreadError = () => {
  throw new Error("Accessed unavailable comment thread");
};

type CommentThreadProps = {
  threadId: string;
  commentsConfig?: TextEditorCommentsPluginConfig;
  onAddReply: ({ message }: { message: string }) => Promise<void>;
  onRemoveComment: ({ id }: { id: string }) => Promise<void>;
  onResolve?: () => Promise<void>;
  onReopen?: () => Promise<void>;
  isTogglingResolve?: boolean;
  onUpdate: (
    data: { id: string } & Partial<CommentMessageType>,
  ) => Promise<void>;
  onCancel: () => void;
  onEditStart: () => void;
  onEditFinish: () => void;
  onInputChange?: (value: string) => void;
  onLoad?: (threadId: string) => void;
  editMode: TextEditorEditCommentMode;
  isInSidebar?: boolean;
  isReadOnly?: boolean;
};

export const CommentThread = ({
  editMode,
  threadId,
  isReadOnly,
  isInSidebar,
  commentsConfig,
  onUpdate,
  onInputChange,
  onRemoveComment,
  onResolve,
  onReopen,
  isTogglingResolve,
  onEditStart,
  onEditFinish,
  onAddReply,
  onCancel,
  onLoad,
}: CommentThreadProps) => {
  const threadContainerRef = useRef<HTMLDivElement>(null);
  const { activeProject } = useActiveProject();

  const isQueryEnabled = !!threadId && !!activeProject?.id;

  const { data: foundingComment, isPending: isFoundingCommentPending } =
    useGetCommentById(
      {
        projectId: activeProject.id,
        id: threadId,
      },
      {
        enabled: isQueryEnabled,
      },
    );

  const { data: commentsThreadData } = useGetCommentsByThreadId(
    {
      projectId: activeProject.id,
      threadId,
    },
    {
      enabled: isQueryEnabled,
    },
  );

  const comments = useMemo(() => {
    return [
      commentsThreadData?.filter(
        (comment) => !comment.isDeleted && comment.id !== threadId,
      ),
    ]
      .flat()
      .filter(defined);
  }, [commentsThreadData, threadId]);

  useEffect(() => {
    if (commentsThreadData?.length) {
      const scrollToBottom = () => {
        if (threadContainerRef.current) {
          threadContainerRef.current.scrollIntoView({
            block: "end",
          });
        }
      };

      scrollToBottom();
    }
  }, [commentsThreadData?.length]);

  useEffect(() => {
    if (commentsThreadData) {
      onLoad?.(threadId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsThreadData, onLoad]);

  return (
    <Stack p={2} pb={isReadOnly ? 1 : undefined} ref={threadContainerRef}>
      {isQueryEnabled && !isFoundingCommentPending && !foundingComment ? (
        <ErrorBoundary
          fallback={<Typography>Failed to load the comment thread</Typography>}
        >
          <ThrowUnavailableThreadError />
        </ErrorBoundary>
      ) : (
        <>
          {/* showing thread founding comment seperately as it should be shown even if deleted if user found a link to it in output */}
          <CommentMessage
            comment={foundingComment}
            commentsConfig={commentsConfig}
            isReadOnly={isReadOnly}
            editMode={editMode}
            onSubmit={onUpdate}
            onRemove={onRemoveComment}
            onToggleResolve={foundingComment?.isResolved ? onReopen : onResolve}
            isTogglingResolve={isTogglingResolve}
            onEditStart={onEditStart}
            onEditFinish={onEditFinish}
            onInputChange={onInputChange}
            showThreadLine={comments.length > 0}
            showQuote={isInSidebar}
            alwaysShowMenu={isInSidebar}
          />
          {comments.map((comment, i) => {
            return (
              <CommentMessage
                key={comment.id || "thread-loading"}
                comment={comment}
                commentsConfig={commentsConfig}
                isReadOnly={isReadOnly}
                editMode={editMode}
                onSubmit={onUpdate}
                onRemove={onRemoveComment}
                onEditStart={onEditStart}
                onEditFinish={onEditFinish}
                onInputChange={onInputChange}
                showThreadLine={i !== comments.length - 1}
                alwaysShowMenu={isInSidebar}
              />
            );
          })}
          {!isReadOnly && !isInSidebar && (
            <CommentEditMessage
              editMode="newComment"
              onSubmit={onAddReply}
              onCancel={onCancel}
              onInputChange={onInputChange}
              isReply
            />
          )}
        </>
      )}
    </Stack>
  );
};
