export enum AccountType {
  User = "User",
  Producer = "Producer",
  AccountExecutive = "Account Executive",
  Creative = "Creative",
  Editor = "Editor",
  Creator = "Creator",
}

export const ExternalAccountTypes: AccountType[] = [AccountType.User];

export const InternalAccountTypes: AccountType[] = [
  AccountType.Producer,
  AccountType.AccountExecutive,
  AccountType.Creative,
  AccountType.Editor,
];

export const NonCreatorAccountTypes: AccountType[] = [
  ...InternalAccountTypes,
  ...ExternalAccountTypes,
];

export const CreatorAccountTypes: AccountType[] = [AccountType.Creator];

export enum UserGroup {
  Internal = "internal",
  External = "external",
  Creator = "creator",
}

// eslint-disable-next-line import/no-unused-modules
export const AccountTypesMap: Record<UserGroup, AccountType[]> = {
  [UserGroup.Internal]: InternalAccountTypes,
  [UserGroup.External]: ExternalAccountTypes,
  [UserGroup.Creator]: CreatorAccountTypes,
};

// eslint-disable-next-line import/no-unused-modules
export type UserAvatarDto = {
  url: string;
};

export type User = {
  _id: string;
  email: string;
  accountType: AccountType;
  firstName?: string;
  lastName?: string;
  isActive: boolean;
  phoneNumber?: string;
  avatar?: UserAvatarDto;
};

export type UserTokens = {
  accessToken: string;
};

export type LocalAuthPayloadDto = {
  email: string;
  password: string;
};

export type UserJwtDto = {
  sub: string;
  email: string;
  accountType: AccountType;
};

export type GoogleOAuthCallbackParams = {
  code: string;
  scope: string;
  state?: string;
};

export type RequestResetPasswordDto = {
  email: string;
};

export type UpdatePasswordDto = {
  token: string;
  password: string;
};

export enum TokenAction {
  ResetPassword = "reset",
}

export type ResetPasswordJwtDto = {
  sub: string;
  email: string;
  action: TokenAction;
};

export enum AuthType {
  EmailPassword = "email-password",
  OAuth2 = "oauth2",
}

export enum AuthProvider {
  Local = "local",
  Jwt = "jwt",
  GoogleOAuth = "google-oauth",
}

type AuthProviderData = Record<string, unknown>;

type PartialProfileDto = {
  email: string;
  accountType?: AccountType;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  avatar?: UserAvatarDto;
};

export interface AuthCallbackResponseDto {
  provider: AuthProvider;
  type: AuthType;
  profile: PartialProfileDto;
  data: AuthProviderData;
  userId?: string;
  userTokens?: UserTokens;
}

export type AuthMethodDto = {
  type: AuthType;
  provider: AuthProvider;
  data: AuthProviderData;
};

export type RegisterDto = {
  email: string;
  accountType: AccountType;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  avatarUrl?: string;
  authMethod: AuthMethodDto;
};

export type RegisterPageState = {
  profile: PartialProfileDto;
  authMethod?: AuthMethodDto;
};

export enum OnboardingSteps {
  UserInformation = "register",
  BrandRequiredInformation = "brand-basics",
  BrandAdditionalInformation = "brand-details",
}
