import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type PropsWithChildren,
} from "react";

import {
  useLocation,
  useNavigate,
  useParams,
  type Path,
} from "react-router-dom";

import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { getProjectStatus } from "@ll-web/features/projects/utils/getProjectStatus";
import { ProjectStatusStepMap } from "@ll-web/features/projectWizard/consts/statusStepMap";
import type { WizardSteps } from "@ll-web/features/projectWizard/consts/wizardSteps";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";
import { useProjectWizardSteps } from "@ll-web/features/projectWizard/hooks/useProjectWizardSteps";
import { useUpdateProjectStatus } from "@ll-web/features/projectWizard/hooks/useUpdateProjectStatus";
import { useWizardUserAnalytics } from "@ll-web/features/projectWizard/hooks/useWizardUserAnalytics";
import { makeWizardPath } from "@ll-web/features/projectWizard/utils/navigation";
import { assertDefined } from "@ll-web/utils/types/types";

export enum WizardFlowType {
  CreativeBrief = "creative-brief",
  Generate = "generate",
  Edit = "edit-wizard",
}

const defaultNextStepBlockedReason = "Data is not loaded yet";
const defaultFinalizeBlockedReason =
  "You need to finish all Wizard steps first";

type NavigateStepOptions = {
  preserveQuery?: boolean;
};

type WizardNavigationContextValue = {
  flowType: WizardFlowType;
  steps: WizardSteps[] | null;
  isLoadingSteps: boolean;
  currentStep: WizardSteps | null | undefined;
  currentStepIndex: number | null;
  stepFromProgress: WizardSteps | undefined;
  projectId: string | null | undefined;
  nextStep: WizardSteps | null;
  previousStep: WizardSteps | null;
  isLastStep: boolean;
  goNextStep: (options?: NavigateStepOptions) => void;
  goPreviousStep: (options?: NavigateStepOptions) => void;
  goToStep: (step: WizardSteps, options?: NavigateStepOptions) => void;
  setReasonForBlockedNextStep: (reason: string | null) => void;
  reasonForBlockedNextStep: string | null;
  setReasonForBlockedFinalize: (reason: string | null) => void;
  reasonForBlockedFinalize: string | null;
  makeAbsolutePath: (step: WizardSteps) => Path;
};

const WizardNavigationContext = createContext<WizardNavigationContextValue>(
  {} as WizardNavigationContextValue,
);

WizardNavigationContext.displayName = "WizardNavigationContext";

export function WizardNavigationContextProvider({
  children,
}: PropsWithChildren) {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    id: projectId,
    flowType,
    step: stepFromUrl,
  } = useParams() as {
    id: string;
    flowType: WizardFlowType;
    step: WizardSteps | undefined;
  };
  const { activeProject } = useActiveProject();
  const { activeUser } = useActiveUser();

  useWizardUserAnalytics({
    flowType,
  });

  const status = getProjectStatus({
    activeUser,
    project: activeProject,
  });
  const stepFromProgress = !stepFromUrl
    ? ProjectStatusStepMap[status]
    : undefined;

  const { wizardSteps: steps, isLoadingSteps } = useProjectWizardSteps();

  const currentStepIndex = useMemo(() => {
    if (!steps || !stepFromUrl) {
      return null;
    }
    const index = steps.indexOf(stepFromUrl);
    if (index === -1) {
      return null;
    }

    return index;
  }, [steps, stepFromUrl]);

  const currentStep = useMemo(() => {
    const isStepAvailable = currentStepIndex !== null;

    if (!isStepAvailable) {
      return undefined;
    }

    return stepFromUrl;
  }, [stepFromUrl, currentStepIndex]);

  const isLastStep = currentStepIndex === (steps?.length ?? 0) - 1;
  const [reasonForBlockedNextStep, setReasonForBlockedNextStep] = useState<
    WizardNavigationContextValue["reasonForBlockedNextStep"]
  >(defaultNextStepBlockedReason);
  const [reasonForBlockedFinalize, setReasonForBlockedFinalize] = useState<
    WizardNavigationContextValue["reasonForBlockedFinalize"]
  >(isLastStep ? null : defaultFinalizeBlockedReason);

  useUpdateProjectStatus(
    flowType === WizardFlowType.Generate ? currentStep : undefined,
  );

  const makeAbsolutePath = useCallback(
    (step: WizardSteps): Path => {
      assertDefined(projectId, "projectId");
      assertDefined(flowType, "flowType");

      return {
        ...location,
        pathname: makeWizardPath(projectId, flowType, step),
      };
    },
    [projectId, flowType, location],
  );

  const goToStep = useCallback(
    (step: WizardSteps, options?: NavigateStepOptions) => {
      if (!step) {
        throw new Error(`No step provided`);
      }
      navigate(
        {
          ...makeAbsolutePath(step),
          ...(!options?.preserveQuery ? { search: undefined } : {}),
        },
        { state: location.state },
      );
      setReasonForBlockedNextStep(defaultNextStepBlockedReason);
      setReasonForBlockedFinalize(defaultFinalizeBlockedReason);
    },
    [navigate, makeAbsolutePath, location],
  );

  const nextStep = useMemo<WizardSteps | null>(() => {
    if (currentStepIndex === null || !steps) {
      return null;
    }
    const nextStep = steps[currentStepIndex + 1];
    if (!nextStep) {
      return null;
    }

    return nextStep;
  }, [steps, currentStepIndex]);

  const goNextStep = useCallback<WizardNavigationContextValue["goNextStep"]>(
    (options) => {
      if (!nextStep) {
        throw new Error(`No next step after ${currentStep}`);
      }
      goToStep(nextStep, options);
    },
    [goToStep, nextStep, currentStep],
  );

  const previousStep = useMemo<WizardSteps | null>(() => {
    if (currentStepIndex === null || !steps) {
      return null;
    }
    const previousStep = steps[currentStepIndex - 1];
    if (!previousStep) {
      return null;
    }

    return previousStep;
  }, [steps, currentStepIndex]);

  const goPreviousStep = useCallback<
    WizardNavigationContextValue["goPreviousStep"]
  >(
    (options) => {
      if (!previousStep) {
        throw new Error(`No previous step before ${currentStep}`);
      }
      goToStep(previousStep, options);
    },
    [goToStep, previousStep, currentStep],
  );

  const value = useMemo(
    () => ({
      flowType,
      steps,
      isLoadingSteps,
      currentStep,
      currentStepIndex,
      stepFromProgress,
      projectId,
      nextStep,
      previousStep,
      isLastStep,
      goNextStep,
      goPreviousStep,
      goToStep,
      reasonForBlockedNextStep,
      setReasonForBlockedNextStep,
      reasonForBlockedFinalize,
      setReasonForBlockedFinalize,
      makeAbsolutePath,
    }),
    [
      flowType,
      steps,
      isLoadingSteps,
      currentStep,
      currentStepIndex,
      stepFromProgress,
      projectId,
      nextStep,
      previousStep,
      isLastStep,
      goNextStep,
      goPreviousStep,
      goToStep,
      reasonForBlockedNextStep,
      setReasonForBlockedNextStep,
      reasonForBlockedFinalize,
      setReasonForBlockedFinalize,
      makeAbsolutePath,
    ],
  );

  return (
    <WizardNavigationContext.Provider value={value}>
      {children}
    </WizardNavigationContext.Provider>
  );
}

export const useWizardNavigationContext = () =>
  useContext(WizardNavigationContext);
