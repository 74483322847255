import { useEffect, type ReactNode } from "react";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { useCreator } from "@ll-web/creator/auth/hooks/useCreator";
import { useCreatorLogOut } from "@ll-web/creator/auth/hooks/useLogOut";
import type { AccountType } from "@ll-web/creator/auth/types";
import { CreatorPages } from "@ll-web/creator/pages";
import { makeNextParamForCurrent } from "@ll-web/utils/helpers/navigation";

interface IsLoggedInGuardProps {
  children: ReactNode;
  accountTypes?: AccountType[];
  emailHint?: string;
  redirectPage?: CreatorPages.Login | CreatorPages.Register;
}

export const IsLoggedInGuard = ({
  children,
  emailHint,
  accountTypes,
  redirectPage = CreatorPages.Login,
}: IsLoggedInGuardProps) => {
  const { isLoading, isAuthorized, activeCreator } = useCreator();
  const logOut = useCreatorLogOut({ activeCreator });

  const isAllowed =
    isAuthorized &&
    (accountTypes?.length
      ? accountTypes.includes(activeCreator.accountType)
      : true);

  useEffect(() => {
    if (!isLoading && !isAllowed) {
      logOut({
        redirect: `${redirectPage}?${makeNextParamForCurrent()}${
          emailHint ? `&${new URLSearchParams({ email: emailHint })}` : ""
        }`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllowed, isLoading]);

  if (!isAllowed) {
    return <SuspenseLoader />;
  }

  return children;
};
