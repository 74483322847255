import { AccountType, type User } from "@ll-web/features/auth/types";
import {
  BrandRoleEnum,
  type BrandUserWithProfile,
} from "@ll-web/features/brands/types";
import { ProjectStyleEnum } from "@ll-web/features/projects/enums";
import { ProjectSpacePages } from "@ll-web/features/projectSpace/enums";
import { makeProjectSpacePagePath } from "@ll-web/features/projectSpace/utils/navigation";
import { WizardFlowType } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { makeWizardPath } from "@ll-web/features/projectWizard/utils/navigation";
import { filterUsersByType } from "@ll-web/features/users/utils/filterUsers";
import { defined } from "@ll-web/utils/types/types";

import { BLOCKING_PHRASES } from "./consts";
import { ReviewNotificationType } from "./types";

const notifyReviewEmailDefaults: Record<
  ReviewNotificationType,
  {
    title: ({
      projectName,
      brandName,
    }: {
      projectName: string;
      brandName?: string;
    }) => string;
    message: (props: {
      projectName: string;
      defaultReceiverFirstName: string;
      projectId: string;
      brandName?: string;
      msa: string;
      accountExecutiveName?: string;
      producerName?: string;
      projectStyle: ProjectStyleEnum;
    }) => string;
  }
> = {
  [ReviewNotificationType.CreativeBriefInternal]: {
    title: ({ brandName }) =>
      `A new project ${brandName ? `for ${brandName}` : ""} is ready for your review`,
    message: ({
      projectName,
      defaultReceiverFirstName,
      projectId,
      brandName,
      msa,
      accountExecutiveName,
    }) =>
      `${defaultReceiverFirstName},

The project details have been submitted for:

${brandName}
${msa}, ${projectName}.

Review here: ${location.origin}${makeWizardPath(projectId, WizardFlowType.CreativeBrief)}

Please review the output and prepare the documents for the client prior to the Creative Review call.

If you have any questions or need more information, feel free to reach out ${accountExecutiveName ? `to the AE, ${accountExecutiveName}` : "."}.

Best,
Lemonlight`,
  },
  [ReviewNotificationType.WizardGenerateInternal]: {
    title: () => "Review Your Video Project Details",
    message: ({
      defaultReceiverFirstName,
      projectId,
      brandName,
      producerName,
      projectName,
    }) => `Hi ${defaultReceiverFirstName},

The pre-production documents ${brandName ? `for ${brandName}` : ""} are now ready for review.

Step 1: Review the project details for project ${projectName} here: ${location.origin}${makeWizardPath(projectId)}. You can make changes or leave feedback directly on our Hero platform.

Step 2: Schedule your Creative Review call - ${BLOCKING_PHRASES.hubspotLink.text} to review the documents with your Producer. On this call, we will fine-tune all production details and ensure everything aligns perfectly with your vision.

If you have any questions or need assistance, don't hesitate to reach out.

Best,
${producerName}`,
  },
  [ReviewNotificationType.WizardGenerateExternal]: {
    title: ({ brandName }) => {
      return `New comments ready for review ${brandName ? `on ${brandName}` : ""}`;
    },
    message: ({
      defaultReceiverFirstName,
      projectId,
      projectName,
      msa,
      brandName,
      projectStyle,
    }) => {
      return `${defaultReceiverFirstName},

Notes have been left on the following project:

${brandName}
${msa}, ${projectName}

Review here:
${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CreativeDeck)}
${projectStyle === ProjectStyleEnum.DocStyle ? `${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CallSheets)}` : ""}`;
    },
  },
  [ReviewNotificationType.CreativeDeckEditInternal]: {
    title: () => `Great news! Pre-production is ready for final approval`,
    message: ({ defaultReceiverFirstName, projectId, projectStyle }) => {
      const creativeDeckLink = `${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CreativeDeck)}`;
      const callSheetLink =
        projectStyle === ProjectStyleEnum.DocStyle
          ? `${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CallSheets)}`
          : "";

      return `${defaultReceiverFirstName},

I'm excited to let you know that your pre-production documents are now finalized. Please take a moment to review:

Creative Deck - ${creativeDeckLink}: Outlines all of the creative details for the videos.${
        callSheetLink
          ? `
Call Sheet - ${callSheetLink}: Outlines the logistical details of the production day.`
          : ""
      }

If you have any additional feedback before you approve, you may also leave comments directly in the platform.

Let us know if you have any questions. Looking forward to your feedback!

Best,
Lemonlight`;
    },
  },
  [ReviewNotificationType.CreativeDeckEditExternal]: {
    title: ({ projectName }) =>
      `The Creative Deck for ${projectName} has been updated`,
    message: ({ defaultReceiverFirstName, projectId }) =>
      `Hi ${defaultReceiverFirstName},

We've updated the Creative Deck to align with the project vision we previously discussed.
Please take a moment to review it and ensure everything meets your expectations.

You can access the Creative Deck here:
${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CreativeDeck)}`,
  },
};

export const getDefaultReceiverForNotificationType = {
  [ReviewNotificationType.CreativeBriefInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find((user) => user.user?.accountType === AccountType.Creative),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
  [ReviewNotificationType.WizardGenerateInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.role === BrandRoleEnum.Owner),
      users.find((user) => user.user?.accountType === AccountType.Creative),
      users.find((user) => user.user?.accountType === AccountType.Producer),
    ].filter(defined);
  },
  [ReviewNotificationType.WizardGenerateExternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find((user) => user.user?.accountType === AccountType.Creative),
    ].filter(defined);
  },
  [ReviewNotificationType.CreativeDeckEditInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.role === BrandRoleEnum.Owner),
      users.find((user) => user.user?.accountType === AccountType.Creative),
      users.find((user) => user.user?.accountType === AccountType.Producer),
    ].filter(defined);
  },
  [ReviewNotificationType.CreativeDeckEditExternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
    ].filter(defined);
  },
};

export const getNotifyFormDefaultValues = ({
  projectName,
  notificationType,
  defaultReceivers,
  defaultReceiversData,
  projectId,
  brandName,
  msa,
  projectStyle,
}: {
  projectName: string;
  notificationType: ReviewNotificationType;
  defaultReceivers: string[];
  defaultReceiversData: User[];
  projectId: string;
  brandName?: string;
  msa: string;
  projectStyle: ProjectStyleEnum;
}) => {
  return {
    people: defaultReceivers ?? [],
    ...notifyReviewEmailDefaults[notificationType],
    title: notifyReviewEmailDefaults[notificationType].title({
      projectName,
      brandName,
    }),
    message: notifyReviewEmailDefaults[notificationType].message({
      projectName,
      defaultReceiverFirstName:
        defaultReceiversData.find(
          (userData) => userData._id === defaultReceivers[0],
        )?.firstName ?? "",
      projectId,
      brandName,
      msa,
      accountExecutiveName: filterUsersByType({
        users: defaultReceiversData,
        type: AccountType.AccountExecutive,
      })?.[0]?.firstName,
      producerName: filterUsersByType({
        users: defaultReceiversData,
        type: AccountType.Producer,
      })?.[0]?.firstName,
      projectStyle,
    }),
  };
};
