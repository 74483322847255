import {
  createBrowserRouter,
  Navigate,
  Outlet,
  type RouteObject,
} from "react-router-dom";

import { BaseLayout } from "@ll-web/components/layouts/BaseLayout";
import {
  DashboardLayout,
  ExternalDashboardLayout,
} from "@ll-web/components/layouts/DashboardLayout/DashboardLayout";
import { APP_CONFIG, AppEnvEnum } from "@ll-web/config/app.config";
import { Pages } from "@ll-web/core/router/pages";
import { creatorRoutes } from "@ll-web/creator/router";
import {
  IsExternalLoggedInGuard,
  IsInternalLoggedInGuard,
  IsLoggedInGuard,
} from "@ll-web/features/auth/guards/IsLoggedInGuard";
import { AuthRedirectController } from "@ll-web/features/auth/pages/AuthRedirectController";
import { OnboardingLayout } from "@ll-web/features/auth/pages/onboard/components/OnboardingLayout/OnboardingLayout";
import {
  IsBrandMemberLoggedInGuard,
  IsBrandOwnerLoggedInGuard,
} from "@ll-web/features/brands/guards/IsBrandMemberLoggedIn";
import { InternalDashboardLayout } from "@ll-web/features/internalDashboard/components/layout/InternalDashboardLayout";
import { NavigationBarLayout } from "@ll-web/features/internalDashboard/components/layout/NavigationBarLayout";
import { InternalDashboardRedirectController } from "@ll-web/features/internalDashboard/pages/InternalDashboardRedirectController";
import { EditProjectFormProvider } from "@ll-web/features/internalProjects/pages/ProjectForm/routeStepper/EditProjectFormProvider";
import { ProjectFormRouteLayout } from "@ll-web/features/internalProjects/pages/ProjectForm/routeStepper/ProjectFormRouteLayout/ProjectFormRouteLayout";
import { ProjectFormRouteProvider as ProjectFormProvider } from "@ll-web/features/internalProjects/pages/ProjectForm/routeStepper/ProjectFormRouteProvider";
import { ProjectSpaceFeatureFlagGuard } from "@ll-web/features/projectSpace/pages/ProjectSpaceFeatureFlagGuard";
import { WizardLayout } from "@ll-web/features/projectWizard/components/layout/WizardLayout";
import ActiveProjectContainer from "@ll-web/features/projectWizard/containers/ActiveProjectContainer";
import { WizardNavigationContextProvider } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { ErrorPage } from "@ll-web/features/statusPages/pages/ErrorPage";
import { VideoPlayerPlayground } from "@ll-web/features/videoPlayer/VideoPlayerPlayground";

import { RedirectInternalProjectSpace } from "./components/RedirectInternalProjectSpace";
import { lazyLoad, withLoader } from "./helpers";

const Status404 = withLoader(
  lazyLoad(() => import("@ll-web/features/statusPages/pages/Status404"))
    .default,
);

const ProjectsListPage = withLoader(
  lazyLoad(
    () =>
      import("@ll-web/features/projects/pages/ProjectsList/ProjectsListPage"),
  ).default,
);
const InternalBrandPage = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/features/brands/pages/internal/InternalBrandPage/InternalBrandPage"
      ),
  ).default,
);

const ProjectFormController = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/features/internalProjects/pages/ProjectForm/routeStepper/ProjectFormRouteController"
      ),
  ).ProjectFormRouteController,
);

const ProjectFormDefaultStepController = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/features/internalProjects/pages/ProjectForm/routeStepper/ProjectFormDefaultStepRouteController"
      ),
  ).ProjectFormDefaultStepRouteController,
);

const InternalProjectListPage = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/features/internalProjects/pages/ProjectsListPage/ProjectsListPage"
      ),
  ).default,
);
const ContractsPage = withLoader(
  lazyLoad(
    () =>
      import("@ll-web/features/contracts/pages/ContractsPage/ContractsPage"),
  ).ContractsPage,
);
const NewContractPage = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/features/contracts/pages/NewContractPage/NewContractPage"
      ),
  ).NewContractPage,
);
const EditContractPage = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/features/contracts/pages/EditContractPage/EditContractPage"
      ),
  ).EditContractPage,
);

const ProjectSpaceDefaultRouteController = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/features/projectSpace/pages/ProjectSpaceDefaultRouteController"
      ),
  ).ProjectSpaceDefaultRouteController,
);

const ProjectSpaceController = withLoader(
  lazyLoad(
    () => import("@ll-web/features/projectSpace/pages/ProjectSpaceController"),
  ).ProjectSpaceController,
);

const LoginPage = withLoader(
  lazyLoad(() => import("@ll-web/features/auth/pages/login/pages/LoginPage"))
    .LoginPage,
);

const ResetPasswordController = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/features/auth/pages/resetPassword/ResetPasswordController"
      ),
  ).ResetPasswordController,
);

const RegisterPage = withLoader(
  lazyLoad(() => import("@ll-web/features/auth/pages/register/RegisterPage"))
    .RegisterPage,
);

const OnboardController = withLoader(
  lazyLoad(
    () => import("@ll-web/features/auth/pages/onboard/OnboardController"),
  ).OnboardController,
);

const OnboardDefaultStepController = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/features/auth/pages/onboard/OnboardDefaultStepController"
      ),
  ).OnboardDefaultStepController,
);

const GoogleOAuthCallbackController = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/features/auth/pages/callbacks/google/GoogleOAuthCallbackController"
      ),
  ).GoogleOAuthCallbackController,
);

const AcceptTeamInvitationController = withLoader(
  lazyLoad(
    () =>
      import("@ll-web/features/brands/pages/AcceptTeamInvitationController"),
  ).AcceptTeamInvitationController,
);

const PlaygroundsIndex = withLoader(
  lazyLoad(
    () => import("@ll-web/features/internalDashboard/pages/PlaygroundsIndex"),
  ).PlaygroundsIndex,
);

const PromptPlayground = withLoader(
  lazyLoad(
    () =>
      import("@ll-web/features/llm/pages/PromptPlayground/PromptPlayground"),
  ).PromptPlayground,
);

const WardrobePlayground = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/features/projectWizard/pages/WardrobePlayground/WardrobePlayground"
      ),
  ).WardrobePlayground,
);

const ThemePlayground = withLoader(
  lazyLoad(() => import("@ll-web/core/theme/playground/ThemePlayground"))
    .ThemePlayground,
);

const TextEditorPlayground = withLoader(
  lazyLoad(() => import("@ll-web/features/textEditor/TextEditorPlayground"))
    .TextEditorPlayground,
);

const ProjectSpacePlayground = withLoader(
  lazyLoad(() => import("@ll-web/features/projectSpace/ProjectSpacePlayground"))
    .ProjectSpacePlayground,
);

const ProjectWizardController = withLoader(
  lazyLoad(
    () =>
      import("@ll-web/features/projectWizard/pages/ProjectWizardController"),
  ).ProjectWizardController,
);
const ProjectWizardDefaultStepController = withLoader(
  lazyLoad(
    () =>
      import(
        "@ll-web/features/projectWizard/pages/ProjectWizardDefaultStepController"
      ),
  ).ProjectWizardDefaultStepController,
);

const MyAccountPage = withLoader(
  lazyLoad(() => import("@ll-web/features/auth/pages/myAccount/MyAccountPage"))
    .MyAccountPage,
);
const TeamSettingsPage = withLoader(
  lazyLoad(() => import("@ll-web/features/brands/pages/TeamSettingsPage"))
    .TeamSettingsPage,
);

const routes: RouteObject[] = [
  {
    path: "",
    element: <BaseLayout />,
    ErrorBoundary: [AppEnvEnum.Production, AppEnvEnum.Staging].includes(
      APP_CONFIG.REACT_APP_ENV,
    )
      ? ErrorPage
      : undefined,
    children: [
      { path: "", element: <AuthRedirectController /> },
      {
        path: "internal",
        children: [
          { path: "", element: <Navigate to="dashboard" replace /> },
          {
            path: "project-space",
            element: <RedirectInternalProjectSpace />,
            children: [
              {
                path: ":id",
                element: <RedirectInternalProjectSpace />,
                children: [
                  {
                    path: "",
                    element: <RedirectInternalProjectSpace />,
                  },
                  {
                    path: ":page",
                    element: <RedirectInternalProjectSpace />,
                  },
                ],
              },
            ],
          },
          {
            path: "register",
            element: <Navigate to="/register" replace />,
          },
          {
            path: "login",
            element: <Navigate to="/login" replace />,
          },

          {
            path: "brand/:id",
            element: (
              <IsInternalLoggedInGuard>
                <InternalDashboardLayout>
                  <Outlet />
                </InternalDashboardLayout>
              </IsInternalLoggedInGuard>
            ),
            children: [
              {
                path: "",
                element: <Navigate to="projects" relative="path" replace />,
              },
              {
                path: ":tab",
                element: <InternalBrandPage />,
              },
            ],
          },
          {
            path: "dashboard",
            element: (
              <IsInternalLoggedInGuard>
                <Outlet />
              </IsInternalLoggedInGuard>
            ),
            children: [
              { path: "", element: <InternalDashboardRedirectController /> },
              {
                path: "projects",
                element: (
                  <InternalDashboardLayout>
                    <InternalProjectListPage />
                  </InternalDashboardLayout>
                ),
              },
              {
                path: "contracts",
                children: [
                  {
                    path: "",
                    element: (
                      <InternalDashboardLayout>
                        <ContractsPage />
                      </InternalDashboardLayout>
                    ),
                  },
                  {
                    path: "new",
                    element: (
                      <NavigationBarLayout>
                        <NewContractPage />
                      </NavigationBarLayout>
                    ),
                  },
                  {
                    path: "edit",
                    children: [
                      {
                        path: ":id",
                        element: (
                          <NavigationBarLayout>
                            <EditContractPage />
                          </NavigationBarLayout>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "project",
            children: [
              {
                path: "new",
                element: (
                  <IsInternalLoggedInGuard>
                    <ProjectFormProvider>
                      <ProjectFormRouteLayout>
                        <Outlet />
                      </ProjectFormRouteLayout>
                    </ProjectFormProvider>
                  </IsInternalLoggedInGuard>
                ),
                children: [
                  {
                    path: "",
                    element: <ProjectFormDefaultStepController />,
                  },
                  {
                    path: ":step",
                    element: <ProjectFormController />,
                  },
                ],
              },
              {
                path: "edit/:id",
                element: (
                  <IsInternalLoggedInGuard>
                    <EditProjectFormProvider>
                      <ProjectFormRouteLayout>
                        <Outlet />
                      </ProjectFormRouteLayout>
                    </EditProjectFormProvider>
                  </IsInternalLoggedInGuard>
                ),
                children: [
                  {
                    path: "",
                    element: <ProjectFormDefaultStepController />,
                  },
                  {
                    path: ":step",
                    element: <ProjectFormController />,
                  },
                ],
              },
            ],
          },
          {
            path: "playgrounds",
            element: (
              <IsInternalLoggedInGuard>
                <NavigationBarLayout>
                  <Outlet />
                </NavigationBarLayout>
              </IsInternalLoggedInGuard>
            ),
            children: [
              {
                path: "",
                element: <PlaygroundsIndex />,
              },
              {
                path: "prompt",
                element: <PromptPlayground />,
              },
              {
                path: "wardrobe",
                element: <WardrobePlayground />,
              },
              {
                path: "theme",
                element: <ThemePlayground />,
              },
              {
                path: "text-editor",
                element: <TextEditorPlayground />,
              },
              {
                path: "project-space",
                element: <ProjectSpacePlayground />,
              },
              {
                path: "video-player",
                element: <VideoPlayerPlayground />,
              },
            ],
          },
          {
            path: "prompt-playground",
            element: <Navigate to={Pages.InternalPromptPlayground} replace />,
          },
          {
            path: "theme-playground",
            element: <Navigate to={Pages.InternalThemePlayground} replace />,
          },
        ],
      },
      {
        path: "dashboard",
        children: [
          {
            path: "",
            element: <Navigate to="projects" replace />,
          },
          {
            path: "projects",
            element: (
              <IsExternalLoggedInGuard>
                <ExternalDashboardLayout>
                  <ProjectsListPage />
                </ExternalDashboardLayout>
              </IsExternalLoggedInGuard>
            ),
          },
          {
            path: "project",
            element: (
              <IsLoggedInGuard>
                <Outlet />
              </IsLoggedInGuard>
            ),
            children: [
              {
                path: ":id",
                children: [
                  {
                    path: ":flowType",
                    element: (
                      <ActiveProjectContainer>
                        <WizardNavigationContextProvider>
                          <WizardLayout>
                            <Outlet />
                          </WizardLayout>
                        </WizardNavigationContextProvider>
                      </ActiveProjectContainer>
                    ),
                    children: [
                      {
                        path: "",
                        element: <ProjectWizardDefaultStepController />,
                      },
                      {
                        path: ":step",
                        element: <ProjectWizardController />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "project-space",
        element: (
          <IsLoggedInGuard>
            <Outlet />
          </IsLoggedInGuard>
        ),
        children: [
          {
            path: ":id",
            element: (
              <ActiveProjectContainer>
                <ProjectSpaceFeatureFlagGuard>
                  <Outlet />
                </ProjectSpaceFeatureFlagGuard>
              </ActiveProjectContainer>
            ),
            children: [
              {
                path: "",
                element: <ProjectSpaceDefaultRouteController />,
              },
              {
                path: ":page",
                element: <ProjectSpaceController />,
              },
            ],
          },
        ],
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
      {
        path: "brand-onboarding",
        element: (
          <IsLoggedInGuard>
            <IsBrandOwnerLoggedInGuard>
              <OnboardingLayout>
                <Outlet />
              </OnboardingLayout>
            </IsBrandOwnerLoggedInGuard>
          </IsLoggedInGuard>
        ),
        children: [
          {
            path: "",
            element: <OnboardDefaultStepController />,
          },
          {
            path: ":step",
            element: <OnboardController />,
          },
        ],
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "reset-password",
        element: <ResetPasswordController />,
      },
      {
        path: "accept-team-invite",
        element: <AcceptTeamInvitationController />,
      },
      {
        path: "auth",
        children: [
          {
            path: "google",
            children: [
              {
                path: "callback",
                element: <GoogleOAuthCallbackController />,
              },
            ],
          },
        ],
      },
      {
        path: "settings",
        element: (
          <IsLoggedInGuard>
            <DashboardLayout>
              <Outlet />
            </DashboardLayout>
          </IsLoggedInGuard>
        ),
        children: [
          {
            path: "my-account",
            element: <MyAccountPage />,
          },
          {
            path: "team/*",
            element: (
              <IsBrandMemberLoggedInGuard>
                <TeamSettingsPage />
              </IsBrandMemberLoggedInGuard>
            ),
          },
        ],
      },
      {
        path: "creator/*",
        children: creatorRoutes,
      },
      {
        path: "status",
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />,
          },
          {
            path: "404",
            element: <Status404 />,
          },
        ],
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
